@use 'assets/styles/z-index' as zIndex;
@use 'mixins';
@import '~swiper/swiper-bundle.min.css';

$cdk-z-index-overlay-container: 1000;

@font-face {
  font-family: MaisonNeue-Mono;
  src: url(./assets/fonts/maisonNeue/MaisonNeue-Mono.otf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: MaisonNeue;
  src: url(./assets/fonts/maisonNeue/MaisonNeueWEB-Light.woff);
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: MaisonNeue;
  src: url(./assets/fonts/maisonNeue/MaisonNeueWEB-Book.woff);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: MaisonNeue;
  src: url(./assets/fonts/maisonNeue/MaisonNeueWEB-Medium.woff);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: MaisonNeue;
  src: url(./assets/fonts/maisonNeue/MaisonNeueWEB-Demi.woff);
  font-weight: 700;
}

@font-face {
  font-family: MaisonNeue;
  src: url(./assets/fonts/maisonNeue/MaisonNeueWEB-Bold.woff);
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Triplay';
  src: url('./assets/fonts/triplay/Triplay.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/triplay/Triplay.woff') format('woff'), url('./assets/fonts/triplay/Triplay.ttf') format('truetype'), url('./assets/fonts/triplay/Triplay.svg#Triplay') format('svg');
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 62.5%;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-size: 1.6rem;
  font-family: MaisonNeue, sans-serif;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  -khtml-user-select: none; /* Konqueror HTML */
  -webkit-touch-callout: none; /* iOS Safari */
}

button {
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  outline-offset: -1px;
}

input:focus {
  outline: none;
}

a {
  color: inherit;
  outline-offset: -1px;
}

.dotted {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.line_clamp {

  &:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 1.2em;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%);
  }
}

@supports (-webkit-line-clamp: 2) {
  .line_clamp {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &:after {
      display: none;
    }
  }
}

.only_on_mobile {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &::after, &::before {
    box-sizing: border-box;
  }
}

.clear {
  clear: both;
}

.container {
  max-width: 1138px;
  margin: 0 auto;

  @include mixins.customBreakpointCss(xl, desktop) {
    width: calc(100% - 32px * 2);
    padding: 0 32px;
  }
  @include mixins.customBreakpointCss(tablet, mobile) {
    width: calc(100% - 20px * 2);
    padding: 0 20px;
  }
}

%button {
  transition: opacity 300ms;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  cursor: pointer;

  &:hover:not([disabled]) {
    opacity: 0.7;
  }

  &:active:not([disabled]) {
    opacity: 0.5;
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }
}

%large_button {
  @extend %button;
  font-size: 16px;
  padding-top: 13px;
  padding-bottom: 12px;
  font-weight: 700;
}

%medium_button {
  @extend %button;
  font-size: 12px;
  padding-top: 6px;
  padding-bottom: 5px;
  font-weight: 700;
}

%small_button {
  @extend %button;
  font-size: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: 700;
}

%cyan_button {
  background-color: #048da3;
  color: #ffffff;
}

%red_button {
  background-color: #F03A22;
  color: #ffffff;
}

.medium_black_button {
  @extend %medium_button;
  background-color: #000000;
  color: #ffffff;
}

.medium_cyan_button {
  @extend %medium_button, %cyan_button;
}

.small_cyan_button {
  @extend %small_button, %cyan_button;
}

.large_cyan_button {
  @extend %large_button, %cyan_button;
}

.medium_red_button {
  @extend %medium_button, %red_button;
}

.small_red_button {
  @extend %small_button, %red_button;
}

.large_red_button {
  @extend %large_button, %red_button;
}

.cdk-global-scrollblock {
  position: initial;
}

.cdk-overlay-pane.modal {
  &.nft_modal {
    mat-dialog-container.mat-dialog-container {
      padding-bottom: 21px;
    }
  }

  &.no_padding {
    mat-dialog-container.mat-dialog-container {
      padding: 0;
    }
  }
}

.cdk-overlay-pane, .modal_container {
  .mat-dialog-container {
    color: #262626;
    box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.48), 0 0 8px 0 rgba(0, 0, 0, 0.12);
    padding: 45px;

    @include mixins.customBreakpointCss("max-width: 650px") {
      padding: 24px;
      border-radius: 0;
    }
  }

  .title_text {
    font-size: 18px;
    font-weight: 700;
    text-align: left;

    &.long_text {
      font-size: 16px;
    }
  }

  .modal_divider {
    width: 100%;
    height: 1px;
    margin-top: 24px;
    margin-bottom: 24px;
    background: #d8d8d8;

    &.thin_divider {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  .mat-dialog-content {
    // Viewport minus modal's top and bottom padding
    max-height: calc(100vh - 80px)
  }

  &.short_bottom_padding {
    .mat-dialog-container {
      padding-bottom: 21px;
    }
  }

  &.token_upsell_modal {
    .mat-dialog-container {
      background: url(./assets/images/crypto/img-token-benefits-modal@2x.jpg) no-repeat center;
      background-size: cover;
      border-radius: 8px;

      @include mixins.customBreakpointCss(mobile) {
        border-radius: 0;
      }
    }
  }

  &.full_screen {
    .mat-dialog-container {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}


.purchase_modal {
  .braintree-placeholder {
    display: none;
  }


}

.mat-dialog-container {
  position: relative;
}

.mat-simple-snackbar-action {
  color: #048da3;
}

.mat-snack-bar-container {
  max-width: 100% !important;
  margin: 12px auto !important;
  border-radius: 8px !important;
  padding: 16px 45px !important;
  font-size: 14px !important;
  position: relative !important;
  font-family: "MaisonNeue", sans-serif !important;
  color: white !important;

  &.blue {
    background: #054760;
  }

  &.error {
    background: #ff5c4a;
  }

  &.black {
    background: rgba(38, 38, 38, 0.9);
  }
}

.mat-menu-panel.calendar-menu {
  background: #121212;
  border-radius: 0;
  box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.25);

  .mat-menu-content {
    padding-top: 0;
    padding-bottom: 0;
  }

  .mat-menu-item {
    height: auto;
    padding: 10px 16px;
    color: #868E96;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.25px;
  }

  .mat-menu-item:hover:not([disabled]) {
    background: #343A40;
    color: #FFFFFF;
  }
}

@include mixins.customBreakpointCss('max-width: 640px') {
  .cdk-overlay-pane.modal {
    max-width: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    height: 100% !important;
  }
}


#_hj_feedback_container {
  position: relative;
  z-index: 5;
}

@include mixins.customBreakpointCss(mobile) {
  ._hj-widget-container, #_hj_feedback_container {
    display: none;
  }
}

.no_flickr {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

}

.purchase_ticket_highlighted_text {
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #000000;
}

.owner_stream_tabs {
  .mat-tab-header {
    border-bottom: 0;

    .mat-tab-label-content {
      color: white;
      white-space: normal;
    }

    .mat-tab-label-container {
      border-bottom: 2px solid #495057;
    }

    .mat-tab-label {
      height: 30px;
      min-width: 20%;
      padding: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      @include mixins.customBreakpointCss(desktop) {
        min-width: 25%;
      }

      @include mixins.customBreakpointCss(mobile) {
        min-width: 100px;
        height: 28px;
      }
    }

    .mat-tab-label-active {
      font-size: 18px;
      opacity: 1;

      @include mixins.customBreakpointCss(mobile) {
        font-size: 16px;
      }
    }

    .mat-ink-bar {
      background: #F03A22;
      height: 4px;
      border-radius: 2px 2px 0 0;
    }

    .mat-tab-header-pagination-chevron {
      border-color: rgba(255, 255, 255, 0.87);
    }

    .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
      border-color: rgba(255, 255, 255, 0.38);
    }
  }
}

.ticket_list_tabs {
  @extend .owner_stream_tabs;

  .mat-tab-header {
    padding: 20px 16px 16px 16px;

    .mat-tab-label-content {
      margin-bottom: 4px;
    }

    .mat-tab-label {
      height: 30px;
      width: 50%;
      padding: 0;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      min-width: auto;
    }

    .mat-tab-label-active {
      font-size: 16px;
      font-weight: 500;
    }

    .mat-ink-bar {
      background: #F03A22;
      height: 4px;
      border-radius: 2px 2px 0 0;
    }
  }
}

.sound_check_container {
  mat-slide-toggle {
    .mat-slide-toggle-bar {
      background-color: #6C6C6C;
    }
  }
}

.cdk-overlay-container {
  z-index: zIndex.$modal-container;

  .header_profile_menu_backdrop {
    background-color: #212529 !important;
  }

  //.cdk-overlay-backdrop-showing {
  //  background: #000000 !important;
  //  opacity: 0.65 !important;
  //}

  .sign_in_opened_from_header {
    @include mixins.customBreakpointCss(tablet) {
      padding-top: 72px;
    }

    @include mixins.customBreakpointCss(mobile) {
      padding-top: 54px;
    }
  }

  .header_profile_menu_backdrop ~ div.cdk-global-overlay-wrapper {
    max-width: 1138px;
    width: calc(100% - 32px * 2);
    left: 50%;
    transform: translateX(-50%);


    @include mixins.customBreakpointCss(tablet) {
      width: calc(100% - 20px * 2);
    }

    @include mixins.customBreakpointCss(mobile) {
      width: 100%;
    }

    .header_profile_menu {
      right: 0;
    }
  }

  .header_profile_menu {
    position: absolute !important;
    top: 72px;
    width: 298px;
    border-radius: 4px;

    @include mixins.customBreakpointCss(xl) {
      min-height: 534px;
      width: 350px;
      top: 96px;
    }

    @include mixins.customBreakpointCss(desktop) {
      min-height: 518px;
      width: 350px;
    }

    @include mixins.customBreakpointCss(tablet) {
      min-height: 510px;
    }

    @include mixins.customBreakpointCss(mobile) {
      top: 54px;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      right: 0;
      max-width: 100vw !important;
    }

    .mat-dialog-content {
      padding: 0;
      margin: 0;

      @include mixins.customBreakpointCss(mobile) {
        max-height: calc(100vh - 20px);
      }
    }

    .mat-dialog-container {
      padding: 0;
      background-color: black;

      @include mixins.customBreakpointCss(xl, desktop, tablet) {
        box-shadow: 0.5px 2px 10px rgba(255, 255, 255, 0.25);
      }
    }
  }
}

.vjs-poster, .vjs-text-track-display, .vjs-control-bar, .vjs-modal-dialog-description, .vjs-modal-dialog, .vjs-modal-dialog-content {
  display: none;
}

.vjs-tech {
  width: 100%;
  height: 100%;
}

.md_tooltip {
  background: #ffffff !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #343A40 !important;
}

@keyframes opacity-0-1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.image_load_animation_js {
  opacity: 1;
  animation-name: opacity-0-1;
  animation-duration: 500ms;
}

#braintree_paypal_button {
  a#braintree-paypal-button {
    width: 97px !important;
    height: 36px !important;
    border-radius: 4px !important;

    img {
      object-fit: cover;
    }
  }

  #braintree-paypal-loggedin {
    border-top: none !important;
  }
}

.modal-backdrop {
  background-color: #000000;
  opacity: 0.65 !important;
}

[fakeButton][disabled] {
  pointer-events: none;
  opacity: 0.5;
}

.js-empty-space-filler::after {
  background-color: #f1f1f1;
  content: '';
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 100%;
  position: relative;
}
