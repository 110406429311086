$breakpoints: (
  xl: 'min-width: 1376px',
  desktop: 'max-width: 1375px',
  desktop-small: 'max-width: 1020px',
  tablet: 'max-width: 960px',
  mobile: 'max-width: 640px',
  // mf-mobile: Use plain parent tag styling
  mf-tablet: 'min-width: 641px',
  mf-desktop-small: 'min-width: 961px',
  mf-desktop: 'min-width: 1021px',
);


/**
Example usage:
@include customBreakpointCss('max-width:1200px') {
  font-size:13px;
}
 */
@mixin customBreakpointCss($breakpointNames...) {
  @each $breakpointName in $breakpointNames {
    $cssProperty: null;
    @if (map-has_key($breakpoints, $breakpointName)) {
      $cssProperty: map-get($breakpoints, $breakpointName);
    } @else {
      $cssProperty: $breakpointName;
    }
    @media only screen and ($cssProperty) {
      @content
    }
  }
}

@mixin clamp($numOfLines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $numOfLines;
  -webkit-box-orient: vertical;
}

@mixin transform($val) {
  -webkit-transform: $val;
  -moz-transform: $val;
  -ms-transform: $val;
  transform: $val;
}

.clickable_icon {
  transition: opacity 300ms;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  &:active {
    opacity: 0.5;
  }

  &.reverse {
    opacity: 1;

    &:hover {
      opacity: 0.7;
    }
  }

}
